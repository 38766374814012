import React from 'react';

export default function ServiceSection() {
  return (
    <section class="service-section section-md mt-m6 section-clr">
      <div class="container">
        <div class="service-info separate-line mdt-0 g-3 g-md-3 row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1">
          {/* <div class="service-wrap d-flex-center justify-content-sm-center flex-nowrap py-lg-1 mb-2 mb-sm-3 mb-md-0">
            <div class="service-icon d-flex align-items-center">
              <i class="icon anm anm-truck-l"></i>
            </div>
            <div class="service-content ms-3">
              <h6 class="head-font">Free Delivery</h6>
              <p class="text-muted">Free Shipping on all orders</p>
            </div>
          </div> */}
          <div class="service-wrap d-flex-center justify-content-sm-center flex-nowrap py-lg-1 mb-2 mb-sm-3 mb-md-0">
            <div class="service-icon d-flex align-items-center">
              <i class="icon anm anm-credit-card-l"></i>
            </div>
            <div class="service-content ms-3">
              <h6 class="head-font">Professional Support</h6>
              <p class="text-muted">We're there when you need us! 24/7</p>
            </div>
          </div>
          <div class="service-wrap d-flex-center justify-content-sm-center flex-nowrap py-lg-1 mb-0 mb-sm-0 mb-md-0">
            <div class="service-icon d-flex align-items-center">
              <i class="icon anm anm-dollar-sign-l"></i>
            </div>
            <div class="service-content ms-3">
              <h6 class="head-font">Contact US</h6>
              <p class="text-muted">If goods have problems</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

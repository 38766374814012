import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <div class="footer footer-4">
      <div class="footer-top clearfix">
        <div class="container">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-3 col-lg-3 footer-links">
              <h4 class="h4 text-uppercase">Quick Shop</h4>
              <ul>
                <li>
                  <p>Brand</p>
                </li>
                <li>
                  <p>Category</p>
                </li>

                <li>
                  <p>Product</p>
                </li>
              </ul>
            </div>
            <div class="col-12 col-sm-12 col-md-3 col-lg-3 footer-links">
              <h4 class="h4 text-uppercase">My Account</h4>
              <ul>
                <Link to={'/profileview'}>
                  <a>My Account</a>
                </Link>{' '}
                <br />
                <Link to={'/cart'}>
                  <a>View Cart</a>
                </Link>
                {/* <li>
                  <a >Track My Order</a>
                </li> */}
                {/* <li>
                  <a >Help</a>
                </li> */}
              </ul>
            </div>
            <div class="col-12 col-sm-12 col-md-3 col-lg-3 footer-links">
              <h4 class="h4 text-uppercase">Information</h4>
              <ul>
                <li>
                  <a>About Us</a>
                </li>
                <li>
                  <a>Order History</a>
                </li>
                <li>
                  <a>Returns & Exchange</a>
                </li>
                <li>
                  <a>Custom Services</a>
                </li>
                <li>
                  <a>Terms & Condition</a>
                </li>
              </ul>
            </div>
            <div class="col-12 col-sm-12 col-md-3 col-lg-3 footer-contact">
              <h4 class="h4 text-uppercase">Contact Us</h4>
              <p class="address d-flex">
                <i class="icon anm anm-map-marker-al pt-1"></i> Mini Road
                Changuvetty, Kottakkal, Malappuram
              </p>
              <p class="phone d-flex align-items-center">
                <i class="icon anm anm-phone-l"></i>{' '}
                <b class="me-1 d-none">Phone:</b> <a>(+91)8078099472</a>
              </p>
              <p class="email d-flex align-items-center">
                <i class="icon anm anm-envelope-l"></i>{' '}
                <b class="me-1 d-none">Email:</b>{' '}
                <a>adcasterkottakkal@gmail.com</a>
              </p>
              <p class="work-hour d-flex mb-0">
                <i class="icon anm anm-clock-r pt-1"></i>
                <span class="hour-time">
                  Working Hours: <br />
                  Mon - Sun / 9:00 AM - 8:00 PM
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom clearfix">
        <div class="container">
          <div class="row">
            <ul class="list-inline social-icons d-inline-flex align-items-center justify-content-center justify-content-sm-start col-12 col-sm-6 col-lg-4">
              <li class="list-inline-item">
                <a
                  href="#"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Facebook"
                >
                  <i class="icon anm anm-facebook-f"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a
                  href="#"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Twitter"
                >
                  <i class="icon anm anm-twitter"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a
                  href="#"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Pinterest"
                >
                  <i class="icon anm anm-pinterest-p"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a
                  href="#"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Linkedin"
                >
                  <i class="icon anm anm-linkedin-in"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a
                  href="https://www.instagram.com/adcaster.in/"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Instagram"
                >
                  <i class="icon anm anm-instagram"></i>
                </a>
              </li>
              <li class="list-inline-item">
                <a
                  href="#"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Youtube"
                >
                  <i class="icon anm anm-youtube"></i>
                </a>
              </li>
            </ul>
            <div class="copytext text-center col-12 col-md-12 col-lg-4 order-2 order-lg-0 mt-3 mt-lg-0">
              &copy; 2023 <a href="https://www.luzidcraft.com/" target='_blank' style={{color:'white'}}>LuzidCraft.</a>{' '}
              All Rights Reserved.
            </div>
            <ul class="payment-icons d-flex-center justify-content-center justify-content-sm-end col-12 col-sm-6 col-lg-4 mt-3 mt-sm-0">
              <li>
                <i class="icon anm anm-cc-visa"></i>
              </li>
              <li>
                <i class="icon anm anm-cc-mastercard"></i>
              </li>
              <li>
                <i class="icon anm anm-cc-amex"></i>
              </li>
              <li>
                <i class="icon anm anm-cc-paypal"></i>
              </li>
              <li>
                <i class="icon anm anm-cc-discover"></i>
              </li>
              <li>
                <i class="icon anm anm-cc-stripe"></i>
              </li>
              <li>
                <i class="icon anm anm-cc-jcb"></i>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

//this is single product page

import React, { useEffect, useState } from 'react';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer';
import Axios from '../../axios/axios';
import { Link } from 'react-router-dom';

import { getSingleProductsList } from '../../actions/ProductPageAction';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const Product = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const singleProduct_ = useSelector((state) => state.singleProductList);
  const { loading, error, singleproduct } = singleProduct_;


  const [isloading, setIsloading] = useState(false);

  const [product, setProduct] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [availability, setAvailability] = useState('');
  const [days, setDays] = useState(1);
  const [totalAmount, settotalAmont] = useState('');

  const [user, setUser] = useState('');
  const [cartAdd, setAddCart] = useState('');
  const [IsAdded, setIsAdded] = useState(false)
  const [quantity, setQuantity] = useState(1);

  const [productsByCategory, setProductsByCategory] = useState([]);
  const [productsByBrand, setProductsByBrand] = useState([]);


  const [cart, setCart] = useState([]);

  const [userInfo, setUserInfo] = useState(null);


  useEffect(() => {
    if (id) {
      dispatch(getSingleProductsList(id));
    }
  }, [dispatch, id]);

  const fetchData = async () => {
    try {
      // Fetch products by categorywise

      const categoryResponse = await Axios.get(
        `/api/products/bycategory/${singleproduct?.category?._id}`
      );

      setProductsByCategory(categoryResponse.data);

      // Fetch products by brandwise
      const brandResponse = await Axios.get(
        `/api/products/bybrand/${singleproduct?.brand?._id}`
      );
      setProductsByBrand(brandResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    if (singleproduct?.category != null) {
      fetchData();
    }
    const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
    setUserInfo(storedUserInfo);
  }, [singleproduct]);
  // Run once when the component mounts

  useEffect(() => {
    fetchCart();
  }, []);
  const [today, setToday] = useState('')

  useEffect(() => {
    // Function to get today's date in "YYYY-MM-DD" format
    const getTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };
    setToday(getTodayDate())

    // Set the initial value of startDate to today's date
    setStartDate(getTodayDate());
    setEndDate(getTodayDate());
  }, []);

  console.log(startDate, "st")
  console.log(endDate, "Ed")

  const fetchCart = async () => {
    try {
      const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
      const response = await Axios.get(`api/cart/${storedUserInfo._id}`);
      setCart(response?.data?.product);
      console.log(response?.data?.product);
    } catch (error) {
      console.log('Error fetching cart list:', error.message);
    }
  };


  // add to cart////////////////////
  const addToCart = async () => {
    setIsloading(true);

    try {


      // Validate days and quantity
      if (days < 1 || quantity < 1) {
        toast.error('Days and quantity must be greater than or equal to one.');
        setIsloading(false);
        return;
      }

      // Perform availability check
      const availabilityCheckData = {
        startDate,
        endDate,
        product: id,
        qnty: quantity,
      };

      const availabilityRes = await Axios.post(`api/order/check`, availabilityCheckData);

      if (availabilityRes.data.code === 200) {
        if (availabilityRes.data.balStock < quantity) {
          toast.error(`Only ${availabilityRes.data.balStock} stock are available`);
          setIsloading(false);
          return; // Stop further execution
        } else {
          toast.success(`Available`);
        }
      } else {
        toast.error('Items Not available for this date');
        setIsloading(false);
        return; // Stop further execution
      }

      // Proceed to add the product to the cart
      const CalcAmount = singleproduct.offerPrice * days * quantity;
      const amount = singleproduct.offerPrice;

      // Check if the user is logged in
      if (!userInfo) {
        alert('Please log in to add items to your cart.');
        window.location.href = '/login';
        setIsloading(false);
        return;
      }

      const price = singleproduct?.offerPrice


      const data = {
        user: userInfo?._id,
        totalAmount: CalcAmount,
        product: id,
        startDate,
        endDate,
        days,
        qnty: quantity,
        amount: amount,

      };
      console.log(quantity)
      console.log(data)


      const res = await Axios.post(`api/cart`, data);


      if (res.data.code === 200) {
        setAddCart(res.data.code);
        toast.success('Added to cart');
        window.location.reload()

        toast.success('successfully added to cart ')



      } else {
        setAddCart(res.data.code);
      }
    } catch (error) {
      console.log('Error during add to cart: ', error.message);
      toast.error('Error adding to cart');
    } finally {
      setIsloading(false);
    }
  };

  //book now

  const booknow = async () => {
    setIsloading(true);

    try {


      // Validate days and quantity
      if (days < 1 || quantity < 1) {
        toast.error('Days and quantity must be greater than or equal to one.');
        setIsloading(false);
        return;
      }

      // Perform availability check
      const availabilityCheckData = {
        startDate,
        endDate,
        product: id,
        qnty: quantity,
      };

      const availabilityRes = await Axios.post(`api/order/check`, availabilityCheckData);

      if (availabilityRes.data.code === 200) {
        if (availabilityRes.data.balStock < quantity) {
          toast.error(`Only ${availabilityRes.data.balStock} stock are available`);
          setIsloading(false);
          return; // Stop further execution
        } else {
          toast.success(`Available`);
        }
      } else {
        toast.error('Items Not available for this date');
        setIsloading(false);
        return; // Stop further execution
      }

      // Proceed to add the product to the cart
      const CalcAmount = singleproduct.offerPrice * days * quantity;
      const amount = singleproduct.offerPrice;

      // Check if the user is logged in
      if (!userInfo) {
        alert('Please log in to add items to your cart.');
        window.location.href = '/login';
        setIsloading(false);
        return;
      }

      const price = singleproduct?.offerPrice


      const data = {
        user: userInfo?._id,
        totalAmount: CalcAmount,
        product: id,
        startDate,
        endDate,
        days,
        qnty: quantity,
        amount: amount,

      };
      console.log(quantity)
      console.log(data)


      const res = await Axios.post(`api/cart`, data);


      if (res.data.code === 200) {
        setAddCart(res.data.code);
        toast.success('Added to cart');
        window.location.href = '/cart';

      } else {
        setAddCart(res.data.code);
      }
    } catch (error) {
      console.log('Error during add to cart: ', error.message);
      toast.error('Error adding to cart');
    } finally {
      setIsloading(false);
    }
  };

  // search availability of product/////////////////
  const availabilityCheck = async () => {
    try {


      if (days < 1 || quantity < 1) {
        toast.error('Days and quantity must be greater than or equal to one.');
        setIsloading(false);
        return;
      }


      const data = {
        startDate,
        endDate,
        product: id,
        qnty: quantity,
      };

      console.log(data)

      const res = await Axios.post(`api/order/check`, data);
      if (res.data.code === 200) {
        if (res.data.balStock < quantity) {
          toast.error(`Only ${res.data.balStock} stock are available`);
        } else {
          toast.success(`Available `);
        }
      } else {
        // setAvailability(res.data.code);
        toast.error('Items Not available for this date');
      }
    } catch (error) {
      console.error('Error during availability check:', error);
      toast.error('Error checking availability');
    }
  };




  let isCartAdded = cart?.some((data) => data.product == id);



  // const handleStartDate = (val) => {
  //   setStartDate(val);
  //   handleNumberOfDays(days, val);
  // };

  const handleNumberOfDays = (val, date_) => {
    setDays(val);

    const startDate_ = new Date(date_);
    startDate_.setDate(startDate_.getDate() + parseInt(val, 10) - 1);

    const year = startDate_.getFullYear();
    const month = startDate_.getMonth() + 1; // Months are 0-indexed, so add 1
    const day = startDate_.getDate();

    let date = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''
      }${day}`;

    setEndDate(date);
  };

  const handleQuantity = (value) => {
    setQuantity(value);
  };

  const headingStyle = {
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#333', // Choose your desired text color
    margin: '20px 0',
    lineHeight: '1.5',
  };


  const price = singleproduct?.offerPrice




  return (
    <body className="template-index index-demo4">
      <div className="page-wrapper">
        <div className="classic-topHeader">
          <Header />
          <div id="page-content">
            {' '}
            <div class="page-header text-center">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="breadcrumbs">
                      <a href="#" title="Back to the home page">
                        Home
                      </a>
                      <span class="main-title fw-bold">
                        <i class="icon anm anm-angle-right-l"></i>Products
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>{' '}
            <div class="container">
              <div class="product-single">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-12 col-12 product-layout-img mb-4 mb-md-0">
                    <div class="product-details-img product-horizontal-style">
                      <div class="zoompro-wrap">
                        <div class="zoompro-span">
                          <img
                            id="zoompro"
                            class="zoompro"
                            // src={singleproduct?.image1}
                            src={
                              singleproduct?.image1
                                ? singleproduct?.image1
                                : '/assets/images/products/noimage.png'
                            }
                            // data-zoom-image={singleproduct?.image1}
                            // alt="assets/images/products/noimage.jpg"
                            width="325"
                            height="508"
                          />
                        </div>

                        <div class="product-labels">
                          {/* <span class="lbl pr-label1">New</span>
                          <span class="lbl on-sale">Sale</span> */}
                        </div>

                        <div class="product-buttons">
                          <a
                            href="#;"
                            class="btn btn-primary prlightbox"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Zoom Image"
                          >
                            <i class="icon anm anm-expand-l-arrows"></i>
                          </a>
                        </div>
                      </div>

                      <div class="lightboximages">
                        <img
                          class="blur-up lazyload"
                          src={
                            singleproduct?.image3 != undefined
                              ? //   ||
                              // singleproduct?.image3 != ''
                              singleproduct?.image3
                              : '/assets/images/products/noimage.png'
                          }
                          alt="product"
                          width="150"
                          height="150"
                        />

                        <img
                          class="blur-up lazyload"
                          //  data-src="assets/images/products/product1.jpg"
                          src={
                            // singleproduct?.image3 != '' ||
                            singleproduct?.image3 != undefined
                              ? singleproduct?.image3
                              : '/assets/images/products/noimage.png'
                          }
                          alt="product"
                          width="150"
                          height="150"
                        />
                      </div>
                      {/* <div class="shippingMsg featureText mt-5">
                        <i class="icon anm anm-clock-r"></i>Estimated Delivery
                        within <b id="fromDate">2 to 3 days</b>
                        <b id="toDate"></b>
                      </div> */}
                      {/* <div class="freeShipMsg featureText" data-price="199">
                        <i class="icon anm anm-truck-r"></i>
                        <b class="freeShip"></b> Free shipping
                      </div> */}

                      {/* <div class="social-sharing d-flex-center mt-2 lh-lg">
                        <span class="sharing-lbl fw-600">Share :</span>
                        <a
                          href="#"
                          class="d-flex-center btn btn-link btn--share share-facebook"
                        >
                          <i class="icon anm anm-facebook-f"></i>
                          <span class="share-title">Facebook</span>
                        </a>
                        <a
                          href="#"
                          class="d-flex-center btn btn-link btn--share share-twitter"
                        >
                          <i class="icon anm anm-twitter"></i>
                          <span class="share-title">Tweet</span>
                        </a>
                        <a
                          href="#"
                          class="d-flex-center btn btn-link btn--share share-pinterest"
                        >
                          <i class="icon anm anm-pinterest-p"></i>{' '}
                          <span class="share-title">Pin it</span>
                        </a>
                        <a
                          href="#"
                          class="d-flex-center btn btn-link btn--share share-linkedin"
                        >
                          <i class="icon anm anm-linkedin-in"></i>
                          <span class="share-title">Linkedin</span>
                        </a>
                        <a
                          href="#"
                          class="d-flex-center btn btn-link btn--share share-email"
                        >
                          <i class="icon anm anm-envelope-l"></i>
                          <span class="share-title">Email</span>
                        </a>
                      </div> */}
                    </div>
                  </div>

                  <div class="col-lg-6 col-md-6 col-sm-12 col-12 product-layout-info">
                    <div class="product-single-meta">
                      <h2 class="product-main-title">{singleproduct?.name}</h2>

                      <div class="product-info">
                        <p class="product-stock d-flex">
                          Category:
                          <span class="pro-stockLbl ps-0">
                            <span class="d-flex-center stockLbl instock text-uppercase">
                              {singleproduct?.category?.categoryName}
                            </span>
                          </span>
                        </p>
                        <p class="product-vendor">
                          Brand:
                          <span class="text">
                            <a href="#">{singleproduct?.brand?.brandName}</a>
                          </span>
                        </p>
                      </div>

                      <div class="product-price d-flex-center my-3">
                        <span class="price old-price">
                          {singleproduct?.price}
                        </span>{' '}
                        &nbsp;Per Day
                        <span class="price">₹{singleproduct?.offerPrice}</span>
                      </div>

                      <hr />
                      <h3 className="text-uppercase mb-0" style={{ fontFamily: "sans-serif" }}>
                        {singleproduct?.description}
                      </h3>
                    </div>

                    <div class="product-form product-form-border hidedropdown">
                      <div class="product-action w-100 d-flex-wrap my-3 my-md-4">
                        <div class="col-md-4 mb-4 stretch-card transparent">
                          <p class="mb-4">Start Date</p>
                          <input
                            min={today}
                            type="date"
                            className="form-control"
                            required
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                          />
                        </div>

                        <div className="col-3">
                          {' '}
                          <p class="mb-4">Days</p>
                          <input
                            min="1"
                            type="number"
                            className="form-control"
                            required
                            value={days}
                            onChange={(e) =>
                              handleNumberOfDays(e.target.value, startDate)
                            }
                            disabled={startDate === '' ? true : false}
                          />
                        </div>

                        <div className="col-3">
                          {' '}
                          <p class="mb-4">Quantity</p>
                          <input
                            min="1"
                            type="number"
                            className="form-control"
                            required
                            value={quantity}
                            onChange={(e) => handleQuantity(e.target.value)}
                          />
                        </div>

                        {availability === 200 && (
                          <div
                            class="alert alert-success alert-dismissible fade show mt-2"
                            role="alert"
                          >
                            <strong>Items available for this date!</strong>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="alert"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        )}
                        {availability === 400 && (
                          <div
                            class="alert alert-Danger alert-dismissible fade show mt-2"
                            role="alert"
                          >
                            <strong>Not Available!</strong> Items not available
                            for this date
                            <button
                              type="button"
                              class="close"
                              data-dismiss="alert"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        )}
                      </div>

                      <div>
                        <div class="product-form-submit addcart fl-1 ms-3">
                          <button
                            type="submit"
                            name="add"
                            // class="btn btn-secondary product-form-cart-submit"
                            class={`btn btn-lg checkout w-100  
                            `}
                            onClick={(e) => availabilityCheck()}
                          >
                            CHECK AVAILABILITY
                          </button>
                        </div>
                        <div class="product-form-submit addcart fl-1 ms-3">
                          {isCartAdded === false ? (
                            <div className='row'>
                              <div className='col-6'>
                                {isloading ? (
                                  <button
                                    type="button"
                                    name="add"
                                    className="btn btn-lg my-4 checkout w-45 loading"
                                    disabled
                                  >
                                    Loading...
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    name="add"
                                    className="btn btn-lg my-4 checkout w-45"
                                    onClick={() => addToCart()}
                                  >
                                    Add to cart
                                  </button>
                                )}
                              </div>

                              <div className='col-6'>
                                {isloading ? (
                                  <button
                                    type="button"
                                    name="add"
                                    className="btn btn-lg my-4 checkout w-45 loading"
                                    disabled
                                  >
                                    Processing...
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    name="add"
                                    className="btn btn-lg my-4 checkout w-45"
                                    onClick={() => booknow()}
                                    disabled={availability ? true : false}
                                  >
                                    Book Now
                                  </button>
                                )}
                              </div>
                            </div>
                          ) : (
                            <Link to="/cart">
                              <button
                                type="submit"
                                name="add"
                                className="btn btn-lg my-4 checkout w-100"
                              >
                                View Cart
                              </button>
                            </Link>
                          )}
                          <button
                            type="submit"
                            name="add"
                            className="btn btn-secondary product-form-sold-out d-none"
                            disabled="disabled"
                          >
                            <span>Out of stock</span>
                          </button>
                          <button
                            type="submit"
                            name="add"
                            className="btn btn-secondary product-form-pre-order d-none"
                          >
                            <span>Pre-order Now</span>
                          </button>
                        </div>


                        {cartAdd === 200 && (
                          <div
                            class="alert alert-success alert-dismissible fade show mt-2"
                            role="alert"
                          >
                            <strong>Items available for this date!</strong>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="alert"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>

                          </div>
                        )}
                        {cartAdd === 400 && (
                          <div
                            class="alert alert-Danger alert-dismissible fade show mt-2"
                            role="alert"
                          >
                            <strong>Not Available!</strong> Items not available
                            for this date
                            <button
                              type="button"
                              class="close"
                              data-dismiss="alert"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section class="section product-slider pb-0">
              <div class="container">
                <div class="section-header">
                  <p class="mb-1 mt-0">Discover Similar</p>
                  <h2>Related Products By Category</h2>
                </div>

                <div class="product-slider-4items gp10 arwOut5 grid-products row">
                  {productsByCategory
                    ?.slice(0, 12)
                    .map((categoryResponse, index) => (
                      <div class="item col-item col-2">
                        <div class="product-box">
                          <div class="product-image">
                            <Link to={`/product/${categoryResponse._id}`}>
                              <img
                                className="primary blur-up lazyload mt-4"
                                // data-src="assets/images/products/product1.jpg"
                                // src="assets/images/products/product1.jpg"

                                src={
                                  categoryResponse?.image1 != ''
                                    ? categoryResponse?.image1
                                    : 'assets/images/products/noimage.png'
                                }
                                alt=""
                                title="Product"
                                width="625"
                                height="808"
                              />

                              {/* <img
                              class="hover blur-up lazyload"
                              data-src="assets/images/products/product1-1.jpg"
                              src="assets/images/products/product1-1.jpg"
                              alt="Product"
                              title="Product"
                              width="625"
                              height="808"
                            /> */}
                            </Link>
                          </div>

                          <div class="product-details text-left">
                            <div class="product-name">
                              {categoryResponse.name}
                            </div>

                            <div class="product-price">
                              <span class="price">
                                Orginal price : ₹{categoryResponse.price}
                              </span>
                            </div>
                            <div class="product-price">
                              <span class="price">
                                Offer Price : (₹{categoryResponse.offerPrice})
                              </span>
                            </div>

                            {/* <div class="product-review">
                          <i class="icon anm anm-star"></i>
                          <i class="icon anm anm-star"></i>
                          <i class="icon anm anm-star-o"></i>
                          <i class="icon anm anm-star-o"></i>
                          <i class="icon anm anm-star-o"></i>
                          <span class="caption hidden ms-1">3 Reviews</span>
                        </div>  */}
                          </div>
                        </div>
                      </div>
                    ))}
                  ;
                </div>
              </div>
            </section>
            <section class="section product-slider pb-0">
              <div class="container">
                <div class="section-header">
                  <p class="mb-1 mt-0">Discover Similar</p>
                  <h2>Related Products By Brand</h2>
                </div>

                <div class="product-slider-4items gp10 arwOut5 grid-products row">
                  {productsByBrand?.map((brandResponse, index) => (
                    <div class="item col-item col-2">
                      <div class="product-box">
                        <div class="product-image">
                          <Link to={`/product/${brandResponse._id}`}>
                            <img
                              className="primary blur-up lazyload mt-5"
                              src={
                                brandResponse?.image1 != ''
                                  ? brandResponse?.image1
                                  : 'assets/images/products/noimage.png'
                              }
                              alt="Product"
                              title="Product"
                              width="625"
                              height="808"
                            />
                          </Link>

                          {/* <img
                            class="hover blur-up lazyload"
                            data-src="assets/images/products/product1-1.jpg"
                            src="assets/images/products/product1-1.jpg"
                            alt="Product"
                            title="Product"
                            width="625"
                            height="808"
                          /> */}
                        </div>

                        <div class="product-details text-left">
                          <div class="product-name">{brandResponse.name}</div>

                          <div class="product-price">
                            <span class="price">
                              Orginal price : ₹{brandResponse.price}
                            </span>
                          </div>
                          <div class="product-price">
                            <span class="price">
                              Offer Price : (₹{brandResponse.offerPrice})
                            </span>
                          </div>

                          {/* <div class="product-review">
                            <i class="icon anm anm-star"></i>
                            <i class="icon anm anm-star"></i>
                            <i class="icon anm anm-star-o"></i>
                            <i class="icon anm anm-star-o"></i>
                            <i class="icon anm anm-star-o"></i>
                            <span class="caption hidden ms-1">3 Reviews</span>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  ))}
                  ;
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </div>{' '}
      </div>
    </body>
  );
};

export default Product;

import {
  NEW_ARRIVALS_HOME_PAGE_LIST_REQUEST,
  NEW_ARRIVALS_HOME_PAGE_LIST_SUCCESS,
  NEW_ARRIVALS_HOME_PAGE_LIST_FAIL,
  SHOP_CATEGORY_HOME_PAGE_LIST_REQUEST,
  SHOP_CATEGORY_HOME_PAGE_LIST_SUCCESS,
  SHOP_CATEGORY_HOME_PAGE_LIST_FAIL,
  BANNER_SESSION_HOME_PAGE_LIST_REQUEST,
  BANNER_SESSION_HOME_PAGE_LIST_SUCCESS,
  BANNER_SESSION_HOME_PAGE_LIST_FAIL,
  BRAND_SESSION_HOME_PAGE_LIST_REQUEST,
  BRAND_SESSION_HOME_PAGE_LIST_SUCCESS,
  BRAND_SESSION_HOME_PAGE_LIST_FAIL,
} from '../constants/homePageConstant.js';

export const newArrivalsListReducer = (state = { newArrivals: [] }, action) => {
  switch (action.type) {
    case NEW_ARRIVALS_HOME_PAGE_LIST_REQUEST:
      return { loading: true, newArrivals: [] };
    case NEW_ARRIVALS_HOME_PAGE_LIST_SUCCESS:
      return {
        loading: false,
        newArrivals: action.payload,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case NEW_ARRIVALS_HOME_PAGE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};



export const getCategoryListReducer = (state = { categoryList: [] }, action) => {
  switch (action.type) {
    case SHOP_CATEGORY_HOME_PAGE_LIST_REQUEST:
      return { loading: true, categoryList: [] };
    case SHOP_CATEGORY_HOME_PAGE_LIST_SUCCESS:
      return {
        loading: false,
        categoryList: action.payload,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case SHOP_CATEGORY_HOME_PAGE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};




export const getBannerListReducer = (state = { banner: [] }, action) => {
  switch (action.type) {
    case BANNER_SESSION_HOME_PAGE_LIST_REQUEST:
      return { loading: true, banner: [] };
    case BANNER_SESSION_HOME_PAGE_LIST_SUCCESS:
      return {
        loading: false,
        categoryList: action.payload,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case BANNER_SESSION_HOME_PAGE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getBrandListReducer = (state = { brand: [] }, action) => {
  switch (action.type) {
    case BRAND_SESSION_HOME_PAGE_LIST_REQUEST:
      return { loading: true, brand: [] };
    case BRAND_SESSION_HOME_PAGE_LIST_SUCCESS:
      return {
        loading: false,
        brand: action.payload,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case BRAND_SESSION_HOME_PAGE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


import {
  
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LIST_FAIL,
    SINGLE_PRODUCT_LIST_REQUEST,
    SINGLE_PRODUCT_LIST_SUCCESS,
    SINGLE_PRODUCT_LIST_FAIL,

    
  } from '../constants/productPageConstant.js';

  export const singleProductListReducer = (state = { singleproduct: [] }, action) => {
    
    switch (action.type) {
      case SINGLE_PRODUCT_LIST_REQUEST:
        return { loading: true, singleproduct: [] };
      case SINGLE_PRODUCT_LIST_SUCCESS:
        return {
          loading: false,
          singleproduct: action.payload,
          pages: action.payload.pages,
          page: action.payload.page,
        };
      case SINGLE_PRODUCT_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const productListReducer = (state = { products: [] }, action) => {
    switch (action.type) {
      case PRODUCT_LIST_REQUEST:
        return { loading: true, products: [] };
      case PRODUCT_LIST_SUCCESS:
        return {
          loading: false,
          products: action.payload.products,
          pages: action.payload.pages,
          page: action.payload.page,
        };
      case PRODUCT_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
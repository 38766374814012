export const NEW_ARRIVALS_HOME_PAGE_LIST_REQUEST =
  'NEW_ARRIVALS_HOME_PAGE_LIST_REQUEST';
export const NEW_ARRIVALS_HOME_PAGE_LIST_SUCCESS =
  'NEW_ARRIVALS_HOME_PAGE_LIST_SUCCESS';
export const NEW_ARRIVALS_HOME_PAGE_LIST_FAIL =
  'NEW_ARRIVALS_HOME_PAGE_LIST_FAIL';



export const SHOP_CATEGORY_HOME_PAGE_LIST_REQUEST =
  'SHOP_CATEGORY_HOME_PAGE_LIST_REQUEST';
export const SHOP_CATEGORY_HOME_PAGE_LIST_SUCCESS =
  'SHOP_CATEGORY_HOME_PAGE_LIST_SUCCESS';
export const SHOP_CATEGORY_HOME_PAGE_LIST_FAIL =
  'SHOP_CATEGORY_HOME_PAGE_LIST_FAIL';


export const BANNER_SESSION_HOME_PAGE_LIST_REQUEST =
  'BANNER_SESSION_HOME_PAGE_LIST_REQUEST';
export const BANNER_SESSION_HOME_PAGE_LIST_SUCCESS =
  'BANNER_SESSION_HOME_PAGE_LIST_SUCCESS';
export const BANNER_SESSION_HOME_PAGE_LIST_FAIL =
  'BANNER_SESSION_HOME_PAGE_LIST_FAIL';


export const BRAND_SESSION_HOME_PAGE_LIST_REQUEST =
  'BRAND_SESSION_HOME_PAGE_LIST_REQUEST';
export const BRAND_SESSION_HOME_PAGE_LIST_SUCCESS =
  'BRAND_SESSION_HOME_PAGE_LIST_SUCCESS';
export const BRAND_SESSION_HOME_PAGE_LIST_FAIL =
  'BRAND_SESSION_HOME_PAGE_LIST_FAIL';


import React, { useState } from 'react'
import './ordersuccess.css'
import { Link } from 'react-router-dom'

function OrderSuccessPage() {

const user= localStorage.getItem('userInfo')
const username=JSON.parse(user)
const name =username?.name
console.log(name)
console.log(user)



 


return (
    <>
        
        <div className="order-success-container">
            <img src='tick.png'></img>
            <h1 className="order-success-heading">Hi! &nbsp;{name}</h1>
            <p className="order-success-text">Thank you for your order. Your order has been successfully processed.</p>
            <Link to={'/'}>
       <button className='btn  btn-danger' >Cancel</button>
       
       </Link>

        </div>
    
       
    </>
);

   
}

export default OrderSuccessPage

import React, { useState } from 'react';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer';
import Axios from '../../axios/axios';
import { Link } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

export default function Signup() {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  // const [agree, setAgree] = useState(false);
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [address3, setAddress3] = useState('');
  const [address4, setAddress4] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [loading, setLoading] = useState(false);

  // signup new user
  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    try {
      const response = await Axios.post('/api/user', {
        name,
        phone,
        email,
        password,
        address1,
        address2,
        address3,
        address4,
        phone1: '',
        id1: '',
        id1Sec: '',
        id1Name: '',
        id2: '',
        id2Sec: '',
        id2Name: '',
        photo: '',
        isAdmin: false,
        // address1: address,
      });

      if (response.status === 200) {
        const user = response.data;
        // localStorage.setItem('userInfo', JSON.stringify(user));
        toast.success('Successfully registered');
        navigate('/login');
      } else {
        toast.error('Registration failed: ');
        // setErrorMessage('Registration failed');
      }
    } catch (error) {
      toast.error('phone number already exist');
      // setErrorMessage('An error occurred during registration');
    }

    setLoading(false);
  };

  return (
    <body className="cart-page cart-style1-page">
      <div className="page-wrapper">
        <Header />
        <div id="page-content">
          <div className="page-header text-center">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-between align-items-center">
                  <div className="page-title">
                    <h1>Sign Up</h1>
                  </div>

                  <div className="breadcrumbs">
                    Home
                    <span className="main-title">
                      <i className="icon anm anm-angle-right-l"></i>Sign Up
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>{' '}
          <div className="container">
            <div className="login-register pt-2">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                  <div className="inner h-100">
                    <form
                      onSubmit={handleSubmit}
                      className="customer-form"
                      autoComplete="off"
                    >
                      <h2 className="text-center fs-4 mb-4">
                        Register here if you are a new customer
                      </h2>

                      <p className="text-center mb-4">
                        If you have an account with us, please log in.
                      </p>
                      <div className="form-row">
                        <div className="form-group col-12">
                          <label for="CustomerUsername" className="d-none">
                            Name <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="customer[Username]"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Name"
                            autoComplete="off"
                            required
                          />
                        </div>
                        <div className="form-group col-12">
                          <label for="CustomerUsername" className="d-none">
                            Phone <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="customer[Phone]"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="Phone"
                            autoComplete="off"
                            required
                          />
                        </div>
                        <div className="form-group col-12">
                          <label for="CustomerUsername" class="d-none">
                            Place <span class="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="customer[Phone]"
                            value={address1}
                            onChange={(e) => setAddress1(e.target.value)}
                            placeholder="Place"
                            autoComplete="off"
                            required
                          />
                        </div>
                        <div class="form-group col-12">
                          <label for="CustomerUsername" class="d-none">
                            Post <span class="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="customer[post]"
                            value={address2}
                            onChange={(e) => setAddress2(e.target.value)}
                            placeholder="Post"
                            autoComplete="off"
                            required
                          />
                        </div>
                        <div class="form-group col-12">
                          <label for="CustomerUsername" class="d-none">
                            Pin <span class="required">*</span>
                          </label>
                          <input
                            type="number"
                            name="customer[Phone]"
                            value={address3}
                            onChange={(e) => setAddress3(e.target.value)}
                            placeholder="Pin"
                            autoComplete="off"
                            required
                          />
                        </div>

                        <div class="form-group col-12">
                          <label for="CustomerUsername" class="d-none">
                            Area <span class="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="customer[area]"
                            value={address4}
                            onChange={(e) => setAddress4(e.target.value)}
                            placeholder="Area"
                            autoComplete="off"
                            required
                          />
                        </div>
                        <div class="form-group col-12">
                          <label for="CustomerEmail" class="d-none">
                            Email <span class="required">*</span>
                          </label>
                          <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            required
                            name="customer[email]"
                            autoComplete="off"
                          />
                        </div>
                        <div class="form-group col-12">
                          <label for="CustomerPassword" class="d-none">
                            Password <span class="required">*</span>
                          </label>
                          <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            name="customer[password]"
                            autoComplete="off"
                            required
                          />
                        </div>
                        <div class="form-group col-12">
                          <label for="CustomerConfirmPassword" class="d-none">
                            Confirm Password <span class="required">*</span>
                          </label>
                          <input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            placeholder="Confirm Password"
                            name="customer[ConfirmPassword]"
                            autoComplete="off"
                            required
                          />
                        </div>

                        <div class="form-group col-12 mb-0">
                          {loading ? (
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ) : (
                            <input
                              disabled={password !== confirmPassword || loading}
                              type="submit"
                              className="btn btn-primary btn-lg w-100"
                              value="Sign In"
                            />
                          )}
                        </div>
                      </div>

                      <div class="login-divide">
                        <span className="login-divide-text">OR</span>
                      </div>

                      {/* <p className="text-center fs-6 text-muted mb-3">
                        Sign in with social account
                      </p>
                      <div className="login-social d-flex-justify-center">
                        <a
                          className="social-link facebook rounded-5 d-flex-justify-center"
                          href="#"
                        >
                          <i className="icon anm anm-facebook-f me-2"></i> Facebook
                        </a>
                        <a
                          className="social-link google rounded-5 d-flex-justify-center"
                          href="#"
                        >
                          <i className="icon anm anm-google-plus-g me-2"></i> Google
                        </a>
                        <a
                          className="social-link twitter rounded-5 d-flex-justify-center"
                          href="#"
                        >
                          <i className="icon anm anm-twitter me-2"></i> Twitter
                        </a>
                      </div> */}

                      <div className="login-signup-text mt-4 mb-2 fs-6 text-center text-muted">
                        Already have an account?{' '}
                        <Link
                          to="/login"
                          className="btn-link"
                          style={{ fontSize: '1.2rem', fontWeight: 'bold' }}
                        >
                          Login here
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <ToastContainer />
    </body>
  );
}

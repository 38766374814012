export const utcTimeToLocal = (date) => {
    const utcDate = new Date(date);
    const localDate = new Intl.DateTimeFormat('en-US', {
      timeZoneName: 'short',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    }).format(utcDate);
    const dateObject = new Date(localDate);
  
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1; // Months are 0-based, so add 1
    const day = dateObject.getDate();
    let hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const seconds = dateObject.getSeconds();
  
    const formattedDateTime = `${year}-${month.toString().padStart(2, '0')}-${day
      .toString()
      .padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  
    return formattedDateTime;
  };
  
  export const dateFormate = (date) => {
    const specificDateTime = new Date(date);
  
    const year = specificDateTime.getFullYear();
    const month = specificDateTime.getMonth() + 1; // Months are zero-based, so add 1 to get the correct month
    const day = specificDateTime.getDate();
  
    const hours = specificDateTime.getHours();
    const minutes = specificDateTime.getMinutes();
  
    const formattedDate = `${day}-${String(month).padStart(2, '0')}-${String(
      year
    ).padStart(2, '0')} ${String(hours).padStart(2, '0')}:${String(
      minutes
    ).padStart(2, '0')}`;
  
    return formattedDate;
  };
  
import {
    
    CART_ADD_REQUEST,
    CART_ADD_SUCCESS,
    CART_ADD_FAIL,
    CART_ADD_RESET,
   
} from '../constants/cartPageConstant';


export const cartCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case CART_ADD_REQUEST:
        return { loading: true };
      case CART_ADD_SUCCESS:
        return { loading: false, success: true, cart: action.payload };
      case CART_ADD_FAIL:
        return { loading: false, error: action.payload };
      case CART_ADD_RESET:
        return {};
      default:
        return state;
    }
};
import Axios from '../axios/axios';
import {

    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LIST_FAIL,
    SINGLE_PRODUCT_LIST_REQUEST,
    SINGLE_PRODUCT_LIST_SUCCESS,
    SINGLE_PRODUCT_LIST_FAIL,


} from '../constants/productPageConstant';

// import { logout } from './userActions';



export const getProductsList = (result) =>
    async (dispatch) => {

        try {

            dispatch({ type: PRODUCT_LIST_REQUEST });

            const { data } = await Axios.get(
                `/api/products${result}`



                // const { data } = await Axios.get(`/api/products?category=${categoryFilter}&brand=${brandFilter}`
            );
            console.log(data)

            dispatch({
                type: PRODUCT_LIST_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: PRODUCT_LIST_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };



export const getSingleProductsList = (id) =>
    async (dispatch) => {
        try {


            dispatch({ type: SINGLE_PRODUCT_LIST_REQUEST });

            const { data } = await Axios.get(`/api/products/${id}`
            );

            dispatch({
                type: SINGLE_PRODUCT_LIST_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: SINGLE_PRODUCT_LIST_FAIL,
                payload:
                    error.response && error.response.data.message
                        ? error.response.data.message
                        : error.message,
            });
        }
    };









import React, { useState } from 'react';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer';
import { Link } from 'react-router-dom';
import Axios from '../../axios/axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Login() {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [userId, setUserId] = useState(null);
  const [forgot, setForgot] = useState(null);

  const [loading, setLoading] = useState(false);

  // const storeduserInfo = JSON.parse(localStorage.getItem('userInfo'));
  // if (storeduserInfo && storeduserInfo._id)
  // setUserId(storeduserInfo._id);


  const handleForgot = async () => {
    setForgot(true)


  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await Axios.post(`api/user/loginUser`, {
        // email,
        phone,
        password,
      });
      console.log(response);
      if (response.status === 200) {
        const user = response.data;
        localStorage.setItem('userInfo', JSON.stringify(user));
        toast.success('Successfully logged in');
        setTimeout(() => {
          window.location = '/';
          setLoading(false);
        }, 1000);
      } else {
        const errorData = response.data;
        toast.error(`Login failed: ${errorData.message}`);
        setErrorMessage('Login failed');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error:', error.message);
      toast.error('invalid login');
      setErrorMessage('An error occurred during login');
      setLoading(false);
    }
  };



const handlePassword = async () => {
  try {
      const response = await Axios.post('api/user/forgotPassword', {
          phone,
      });
      console.log(response)

  } catch (error) {
      console.error('Error occured while checking phone number:', error);

  }
};

  return (
    <body class="cart-page cart-style1-page">
      <div class="page-wrapper">
        <Header />

        <div id="page-content">
          <div class="page-header text-center">
            <div class="container">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-between align-items-center">
                  <div class="page-title">
                    <h1>LOGIN HERE </h1>
                  </div>

                  <div class="breadcrumbs">
                    Home
                    <span class="main-title">
                      <i class="icon anm anm-angle-right-l"></i>LOGIN
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>{' '}
          <div class="container">
            <div class="login-register pt-2">
              <div class="row">
                {forgot?  <div class="form-row">
                        <div class="form-group col-12">
                          <label for="CustomerEmail" class="d-none">
                            Phone Number <span class="required">*</span>
                          </label>
                          <input
                            type="number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="phone number"
                            autoComplete="off"
                            required
                          />
                        </div>
                  
                        <div class="form-group col-12 mb-0">
                          <button
                            disabled={loading === true ? true : false}
                            type="submit"
                            className="btn btn-primary btn-lg w-100"
                            onClick={(e) => handlePassword()}
                          >
                            {loading ? (
                              <>
                                Submit...
                                <div
                                  className="spinner-border text-primary"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              </>
                            ) : (
                              'Submit'
                            )}
                          </button>
                        </div>
                      </div>:
                  <div class="col-12 col-sm-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                  <div class="inner h-100">
                    <form
                      class="customer-form"
                      onSubmit={handleSubmit}
                      autoComplete="off"
                    >
                      <h2 class="text-center fs-4 mb-3">
                        Registered Customers
                      </h2>
                      <p class="text-center mb-4">
                        If you have an account with us, please log in.
                      </p>
                      <div class="form-row">
                        <div class="form-group col-12">
                          <label for="CustomerEmail" class="d-none">
                            Phone Number <span class="required">*</span>
                          </label>
                          <input
                            type="number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="phone number"
                            autoComplete="off"
                            required
                          />
                        </div>
                        <div class="form-group col-12">
                          <label for="CustomerPassword" class="d-none">
                            Password <span class="required">*</span>
                          </label>
                          <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            autoComplete="off"
                            required
                          />
                        </div>
                        <div class="form-group col-12">
                          <div class="login-remember-forgot d-flex justify-content-between align-items-center">
                            <div class="remember-check customCheckbox">
                              <input
                                id="remember"
                                name="remember"
                                type="checkbox"
                                value="remember"
                                required
                              />
                              <span onClick={handleForgot}> Forgot Password</span>
                            </div>
                            {/* <a href="forgot-password.html"> */}
                            {/* Forgot your password?
                            </a> */}
                          </div>
                        </div>
                        <div class="form-group col-12 mb-0">
                          <button
                            disabled={loading === true ? true : false}
                            type="submit"
                            className="btn btn-primary btn-lg w-100"
                            onClick={(e) => handleSubmit(e)}
                          >
                            {loading ? (
                              <>
                                Login...
                                <div
                                  className="spinner-border text-primary"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                              </>
                            ) : (
                              'Login'
                            )}
                          </button>
                        </div>
                      </div>

                      <div class="login-divide">
                        <span class="login-divide-text">OR</span>
                      </div>

                      {/* <p class="text-center fs-6 text-muted mb-3">
                        Sign in with social account
                      </p>
                      <div class="login-social d-flex-justify-center">
                        <a
                          class="social-link facebook rounded-5 d-flex-justify-center"
                          href="#"
                        >
                          <i class="icon anm anm-facebook-f me-2"></i> Facebook
                        </a>
                        <a
                          class="social-link google rounded-5 d-flex-justify-center"
                          href="#"
                        >
                          <i class="icon anm anm-google-plus-g me-2"></i> Google
                        </a>
                        <a
                          class="social-link twitter rounded-5 d-flex-justify-center"
                          href="#"
                        >
                          <i class="icon anm anm-twitter me-2"></i> Twitter
                        </a>
                      </div> */}

                      <div class="login-signup-text mt-4 mb-2 fs-6 text-center text-muted">
                        Don,t have an account?{' '}
                        <Link
                          to="/signup"
                          class="btn-link"
                          style={{ fontSize: '1.2rem', fontWeight: 'bold' }}
                        >
                          Sign up now
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>}
              
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </body>
  );
}

import React, { useState } from 'react'
import { useEffect } from 'react';
import Axios from '../../axios/axios';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Layout/Header/Header';


const ProfileView = () => {
    const navigate = useNavigate()
    const [isloading, setIsloading] = useState(false);


    useEffect(() => {
        const UserInfo = localStorage.getItem('userInfo');
        if (UserInfo == null) {
            navigate('/login')
        }
        getUserData()
    }, [])
    const [productData, setProductData] = useState([])
    console.log(productData)

    const getUserData = async () => {
        let user = JSON.parse(localStorage.getItem('userInfo'));
        console.log(user);
        const { data } = await Axios.get(`/api/user/${user._id}`);
        console.log(data)
        setProductData(data)
    }
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        return new Date(dateString).toLocaleString('en-US', options);
    };



    return (
        <>
            {/* <p><strong>Name:</strong> {projectData?.name}</p>
            <p><strong>Email:</strong> {projectData?.email}</p>
            <p><strong>Address Line 1:</strong> {projectData?.address1}</p>
            <p><strong>Address Line 2:</strong> {projectData?.address2}</p>
            <p><strong>Phone:</strong> {projectData?.phone}</p>
            <p><strong>Balance Amount:</strong> ${projectData?.balanceAmount?.toLocaleString()}</p>
            <p><strong>Status:</strong> {projectData?.status === 1 ? 'Active' : 'Inactive'}</p>
            <p><strong>Created At:</strong> {formatDate(projectData?.createdAt)}</p>
            <p><strong>Updated At:</strong> {formatDate(projectData?.updatedAt)}</p>
            <Link to={'/profile'}>
                <button>Update</button>
            </Link> */}

            <body class="cart-page cart-style1-page">
                <div class="page-wrapper">
                    <Header />

                    <div id="page-content">
                        <div class="page-header text-center">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-between align-items-center">
                                        <div class="page-title">
                                            <h1>My Account</h1>
                                        </div>

                                        <div class="breadcrumbs">
                                            <a href="index.html" title="Back to the home page">
                                                Home
                                            </a>

                                            <span class="main-title fw-bold">
                                                <i class="icon anm anm-angle-right-l"></i>My Account
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="container">
                            <form class="forms-sample" >
                                <div class="row">
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-3 mb-4 mb-lg-0">
                                        <div class="dashboard-sidebar bg-block">
                                            <div class="profile-top text-center mb-4 px-3">
                                                {/* <div class="profile-image mb-3">
                                                    <img
                                                        class="rounded-circle blur-up lazyload"
                                                        src={
                                                            userDetails.photo != ''
                                                                ? userDetails.photo
                                                                : '/assets/images/users/user.png'
                                                        }
                                                        alt="user"
                                                        width="130"
                                                    />
                                                </div> */}
                                                <div class="profile-detail">
                                                    <h3 class="mb-1">{productData.name}</h3>
                                                    <p class="text-muted">{productData.email}</p>
                                                </div>
                                                <div class="profile-detail">
                                                    <h3 class="mb-1">
                                                        Balance :₹ {productData.balanceAmount}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 col-md-12 col-lg-9">
                                        <div
                                            class="dashboard-content tab-content h-100"
                                            id="top-tabContent"
                                        >
                                            <div class="tab-pane fade h-100 show active" id="info">
                                                <div class="account-info h-100">
                                                    <div class="account-box">
                                                        <h3 class="mb-3">User Information</h3>
                                                        <div class="row row-cols-lg-2 row-cols-md-2 row-cols-sm-1 row-cols-1">
                                                            <div class="box-info mb-4">
                                                                <div class="box-title d-flex-center">
                                                                    NAME : {productData.name}
                                                                </div>
                                                                <div class="box-title d-flex-center">

                                                                    EMAIL :{productData.email}

                                                                </div>
                                                                <div class="box-title d-flex-center">
                                                                    PLACE : {productData?.address1}

                                                                </div>
                                                                <div class="box-title d-flex-center">
                                                                    POST : {productData.address2}

                                                                </div>
                                                            </div>

                                                            <div class="box-info mb-4">
                                                                <div class="box-title d-flex-center">

                                                                </div>
                                                                <div class="box-title d-flex-center">
                                                                    PIN : {productData.address3}

                                                                </div>
                                                                <div class="box-title d-flex-center">
                                                                    PHONE : {productData.phone}


                                                                </div>
                                                                <div class="box-title d-flex-center">
                                                                    CREATED AT : {formatDate(productData?.createdAt)}


                                                                </div>
                                                                <div class="box-title d-flex-center">
                                                                    UPDATED AT : {formatDate(productData?.updatedAt)}


                                                                </div>
                                                            </div>
                                                            {/* <div class="box-info mb-4">
                                                                Your Photo
                                                                <div class="box-title d-flex-center">
                                                                    <input
                                                                        type="file"
                                                                        className="form-control"
                                                                        placeholder="Address"
                                                                        onChange={(e) =>
                                                                            uploadFileHandler(e, 'photo')
                                                                        }
                                                                    />
                                                                    <img
                                                                        class="rounded-circle blur-up lazyload"
                                                                        src={
                                                                            userDetails.photo != ''
                                                                                ? userDetails.photo
                                                                                : '/assets/images/users/user.png'
                                                                        }
                                                                        alt="user"
                                                                        width="50"
                                                                    />
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                        {/* <div class="row row-cols-lg-2 row-cols-md-2 row-cols-sm-1 row-cols-1">
                                                            <div class="box-info mb-4">
                                                                Id Proof 1[ Adhar /License]
                                                                <div class="box-title d-flex-center">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Adhar /License"
                                                                        onChange={(e) => setId1Name(e.target.value)}
                                                                        value={id1Name}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        {/* <div class="row row-cols-lg-2 row-cols-md-2 row-cols-sm-1 row-cols-1">
                                                            <div class="box-info mb-4">
                                                                Frontside Photo
                                                                <div class="box-title d-flex-center">
                                                                    <input
                                                                        type="file"
                                                                        className="form-control"
                                                                        onChange={(e) => uploadFileHandler(e, 'id1')}
                                                                    />
                                                                    {uploading === true ? (
                                                                        <div class="spinner-border" role="status">
                                                                            <span class="sr-only">Loading...</span>
                                                                        </div>
                                                                    ) : (
                                                                        <img
                                                                            src={
                                                                                id1 === ''
                                                                                    ? process.env.PUBLIC_URL +
                                                                                    '/assets/images/select_image.png'
                                                                                    : `${id1}`
                                                                            }
                                                                            width="130"
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div class="box-info mb-4">
                                                                Backside Photo

                                                                <div class="box-title d-flex-center">
                                                                    <input
                                                                        type="file"
                                                                        className="form-control"
                                                                        onChange={(e) =>
                                                                            uploadFileHandler(e, 'id1Sec')
                                                                        }
                                                                    />
                                                                    {uploading === true ? (
                                                                        <div class="spinner-border" role="status">
                                                                            <span class="sr-only">Loading...</span>
                                                                        </div>
                                                                    ) : (
                                                                        <img
                                                                            src={
                                                                                id1Sec === ''
                                                                                    ? process.env.PUBLIC_URL +
                                                                                    '/assets/images/select_image.png'
                                                                                    : `${id1Sec}`
                                                                            }
                                                                            width="130"
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row row-cols-lg-2 row-cols-md-2 row-cols-sm-1 row-cols-1">
                                                            <div class="box-info mb-4">
                                                                Id Proof 2 [ Adhar /License]
                                                                <div class="box-title d-flex-center">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Adhar /License"
                                                                        onChange={(e) => setId2name(e.target.value)}
                                                                        value={id2Name}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        {/* <div class="row row-cols-lg-2 row-cols-md-2 row-cols-sm-1 row-cols-1">
                                                            <div class="box-info mb-4">
                                                                Frontside Photo

                                                                <div class="box-title d-flex-center">
                                                                    <input
                                                                        type="file"
                                                                        className="form-control"
                                                                        onChange={(e) => uploadFileHandler(e, 'id2')}
                                                                    />
                                                                    {uploading === true ? (
                                                                        <div class="spinner-border" role="status">
                                                                            <span class="sr-only">Loading...</span>
                                                                        </div>
                                                                    ) : (
                                                                        <img
                                                                            src={
                                                                                id2 === ''
                                                                                    ? '/assets/images/select_image.png'
                                                                                    : `${id2}`
                                                                            }
                                                                            width="130"
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div class="box-info mb-4">
                                                                Backside Photo

                                                                <div class="box-title d-flex-center">
                                                                    <input
                                                                        type="file"
                                                                        className="form-control"
                                                                        onChange={(e) =>
                                                                            uploadFileHandler(e, 'id2Sec')
                                                                        }
                                                                    />
                                                                    {uploading === true ? (
                                                                        <div class="spinner-border" role="status">
                                                                            <span class="sr-only">Loading...</span>
                                                                        </div>
                                                                    ) : (
                                                                        <img
                                                                            src={
                                                                                id2Sec === ''
                                                                                    ? '/assets/images/select_image.png'
                                                                                    : `${id2Sec}`
                                                                            }
                                                                            width="130"
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        <div class="box-info mb-4">
                                                            <div class="box-title d-flex-center">
                                                                <div class="product-form-submit addcart fl-1 ms-3">
                                                                    <Link to={'/profile'}>
                                                                        <button  className='btn btn-success'>Update</button>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </body>
        </>
    )
}

export default ProfileView

import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import {
  newArrivalsListReducer,
  getCategoryListReducer,
  getBannerListReducer,
  getBrandListReducer,
} from './reducers/homePageReducer';

import {
  productListReducer,
  singleProductListReducer,
} from './reducers/productPageReducer';
import { cartCreateReducer } from './reducers/cartPageReducer';
import {
  userLoginReducer,
  userRegisterReducer,
} from './reducers/userPageReducer';
import { orderCreateReducer, orderListReducer } from './reducers/orderReducer';

const reducer = combineReducers({
  homePageNewArrivals: newArrivalsListReducer,
  homePageCategoryList: getCategoryListReducer,
  homePageBanner: getBannerListReducer,
  homePageBrand: getBrandListReducer,
  productList: productListReducer,
  singleProductList: singleProductListReducer,
  cartCreate: cartCreateReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  orderList: orderListReducer,
});
const initialState = {
  cart: {
    // cartItems: cartItemsFromStorage,
    // shippingAddress: shippingAddressFromStorage,
  },
  userLogin: {},
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  // composeWithDevTools(applyMiddleware(...middleware))
  compose(
    applyMiddleware(...middleware)
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

export default store;

import React, { useEffect, useState } from 'react';
import Axios from '../../axios/axios';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer';
import { toast } from 'react-toastify';
import OrderSuccessPage from './OrderSuccessPage';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const CartList = () => {
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cartCheckOut, setcartCheckOut] = useState(null);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const navigate = useNavigate();

  console.log(cart);

  useEffect(() => {
    // Retrieve user info from localStorage
    const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (storedUserInfo == null) {
      navigate('/login');
    }

    if (storedUserInfo && storedUserInfo._id) {
      // setUserId(storedUserInfo._id);

      // Fetch cart based on the userId
      const fetchCart = async () => {
        try {
          const response = await Axios.get(`api/cart/${storedUserInfo._id}`);
          setCart(response.data);
          console.log(response.data);
        } catch (error) {
          console.log('Error fetching cart list:', error.message);
        }
      };
      fetchCart();
    } else {
      console.log('User info not found in localStorage or invalid.');
    }
  }, []);
  console.log(cart);

  // checkout
  const handleCheckout = async () => {
    setLoading(true);

    try {
      const customerData = JSON.parse(localStorage.getItem('userInfo'));
      const customer = customerData._id;

      const orderAmount = orderCartData.reduce(
        (total, item) => total + item.prodPrice,
        0
      );

      const data = {
        customer,
        selectedItems: orderCartData,
        advance: 0,
        orderAmount: calculateTotal(),
        discount: 0,
      };

      console.log("ordered data",data);

      const response = await Axios.post(`api/order`, data);
      console.log(response.data);
      console.log(response)

      if (response.data) {
        setcartCheckOut(response.data);
        setTimeout(() => {
          // navigate('/')
        }, 1000);
      }
      toast.success('order placed successfully');
      // localStorage.setItem('orderSuccess', 'true');
      // window.location='/myorders'
      setOrderSuccess(true);
      if (response.status === 400) {
        toast.error('Product stock is not available')
      }
    } catch (error) {
      console.error('Error during checkout:', error.message);
      console.error(
        'Response data:',
        error.response ? error.response.data : 'No response data'
      );
      console.log(error.response.data)
      toast.error(error?.response?.data?.message)





      setLoading(false);
    }
  };

  const subtotal = cart?.product?.reduce(
    (total, item) => total + item.totalAmount,
    0
  );
  console.log(cart.product);

  //  const amount=cart?.product?.map(item=> days?.prodPrice?.quantity)
  //  console.log(amount)
  const orderCartData = cart?.product?.map((cartItem) => ({
    product: cartItem?.product,
    // totalAmount: cartItem?.orderItems[0].totalAmount,
    startDate: cartItem?.startDate,
    endDate: cartItem?.endDate,
    prodPrice: cartItem?.price,
    days: cartItem?.days,
    qnty: cartItem?.qnty,
    amount: cartItem?.totalAmount,
    // amount: cartItem?.amount
  }));

  console.log(cart);
  const calculateTotal = () => {
    let total = 0;
    cart?.product?.map((dt) => {
      let count = dt.qnty * dt.days;

      total += dt.price * count;
    });
    return total;
  };

  ///////////////////////////////////delete cart////////////////////////////////////////////////

  const deleteCart = async (cartId) => {
    try {
      console.log(cartId, 'cartid before confirmation');

      if (window.confirm('Are you sure,Do you want to delete ?')) {
        console.log(cartId, 'cartid after confirmation');

        if (cartId === undefined || cartId === null) {
          return;
        }

        const response = await Axios.delete(`api/cart/${cartId}`);
        console.log('deleted cart item');

        if (response.status === 200) {
          // Remove the deleted cart item from the cart state
          // setCart(prevCart => prevCart.filter(cartItem => cartId !== cartItem.cartId));
          setCart((prevCart) => {
            if (!Array.isArray(prevCart)) {
              console.error('prevCart is not an array:', prevCart);
              return [];
            }
            return prevCart.filter((cartItem) => cartId !== cartItem.cartId);
          });

          console.log(`Cart with ID ${cartId} deleted successfully`);
          const fetchCart = async () => {
            try {
              const storedUserInfo = JSON.parse(
                localStorage.getItem('userInfo')
              );

              const response = await Axios.get(
                `api/cart/${storedUserInfo._id}`
              );
              setCart(response.data);
              console.log(response.data);
            } catch (error) {
              console.log('Error fetching cart list:', error.message);
            }
          };
          fetchCart();
          window.location.reload()
        } else {
          console.log('Failed to delete cart:', response.data.message);
        }
      }
    } catch (error) {
      console.error('Error deleting cart:', error.message);
    }
  };
  // const calculateTotal = () => {
  //   let total = 0;
  //   cart?.product?.map((dt) => {

  //     // const orderCartData = cart?.product?.map(cartItem => ({
  //     //   product: cartItem.orderItems[0].product,
  //     //   totalAmount: cartItem.orderItems[0].totalAmount,
  //     //   startDate: cartItem.orderItems[0].startDate,
  //     //   endDate: cartItem.orderItems[0].endDate,
  //     //   isDelivered: cartItem.orderItems[0].isDelivered,
  //     //   isReturn: cartItem.orderItems[0].isReturn,
  //     //   days: cartItem.orderItems[0].days,
  //     //   cartId: cartItem.orderItems[0]._id
  //     // }));

  //     //////////////////////////////////////delete cart ends here//////////////////////////////////

  //     console.log(cart)

  //   }

  console.log(cart);
  console.log(orderSuccess, 'status');
  return (
    <>
      <body class="cart-page cart-style1-page">
        <div class="page-wrapper">
          <Header />

          <div id="page-content">
            {' '}
            <>
              <div class="page-header text-center">
                <div class="container">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-between align-items-center">
                      <div class="page-title">
                        <h1>Your Shopping Cart </h1>
                      </div>

                      <div class="breadcrumbs">
                        Home
                        <span class="main-title">
                          <i class="icon anm anm-angle-right-l"></i> Cart
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{' '}
              {orderSuccess ? (
                <OrderSuccessPage />
              ) : (
                <div class="container">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-8 main-col">
                      {/* <div
                        class="alert alert-success py-2 alert-dismissible fade show cart-alert"
                        role="alert"
                      >
                        <i class="align-middle icon anm anm-truck icon-large me-2"></i>
                        <strong class="text-uppercase">Congratulations!</strong>{' '}
                        You've got free shipping!
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                        ></button>
                      </div> */}

                      <form
                        action="#"
                        method="post"
                        class="cart-table table-bottom-brd"
                      >
                        <table class="table align-middle">
                          <thead class="cart-row cart-header small-hide position-relative">
                            <tr>
                              <th class="action">&nbsp;</th>
                              <th class="action">&nbsp;</th>
                              <th class="text-start">Product</th>
                              <th class="text-center">Price</th>
                              <th class="text-center">Quantity</th>
                              <th class="text-center">Start Date</th>
                              <th class="text-center">Days</th>
                              <th class="text-center">Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cart?.product?.map((cartItem) => (
                              <tr class="cart-row cart-flex position-relative">
                                <td class="cart-delete text-center small-hide">
                                  <a
                                    href="#"
                                    onClick={() => deleteCart(cartItem?.cartId)}
                                    class="cart-remove remove-icon position-static"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Remove to Cart"
                                  >
                                    <i class="icon anm anm-times-r"></i>
                                  </a>
                                </td>
                                <td class="cart-image cart-flex-item">
                                  <img
                                    className="primary blur-up lazyload mt-4"
                                    src={
                                      cartItem?.proPhoto !== undefined
                                        ? cartItem?.proPhoto
                                        : 'assets/images/products/noimage.png'
                                    }
                                    alt=""
                                    title="Product"
                                    width="120"
                                    height="170"
                                  // style={{ width: '100%' }}
                                  />
                                </td>
                                <td class="cart-meta small-text-left cart-flex-item">
                                  <div class="list-view-item-title">
                                    {cartItem?.productname}
                                  </div>
                                  {/* <div class="cart-meta-text">
                          Color: Black
                          <br />
                          Size: Small
                          <br />
                          Qty: 2
                        </div> */}
                                  {/* <div class="cart-price d-md-none">
                          <span class="money fw-500">price</span>
                        </div> */}
                                </td>
                                <td class="cart-price cart-flex-item text-center small-hide">
                                  <span class="money">{cartItem?.price}</span>
                                </td>
                                <td class="cart-price cart-flex-item text-center small-hide">
                                  <span class="money">
                                    {cartItem?.qnty}
                                  </span>
                                </td>{' '}
                                <td class="cart-price cart-flex-item text-center small-hide">
                                  <span class="money">
                                    {cartItem?.startDate}
                                  </span>
                                </td>{' '}
                                <td class="cart-price cart-flex-item text-center small-hide">
                                  <span class="money">{cartItem?.days}</span>
                                </td>
                                <td class="cart-price cart-flex-item text-center small-hide">
                                  <span class="money">
                                    {cartItem?.totalAmount}
                                  </span>
                                </td>
                                {/* <td class="cart-price cart-flex-item text-center small-hide">
                            <span class="money">{cartItem?.totalAmount}</span>
                          </td> */}
                                <td class="cart-update-wrapper cart-flex-item text-end text-md-center">
                                  <div class="cart-qty d-flex justify-content-end justify-content-md-center"></div>
                                  <a
                                    href="#"
                                    title="Remove"
                                    class="removeMb d-md-none d-inline-block text-decoration-underline mt-2 me-3"
                                  >
                                    Remove
                                  </a>
                                </td>
                                {/* <td class="cart-price cart-flex-item text-center small-hide">
                            <span class="money fw-500">{cart.totalAmount}</span>
                          </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </form>
                    </div>
                    <div class="col-12 col-sm-12 col-md-12 col-lg-4 cart-footer">
                      <div class="cart-info sidebar-sticky">
                        <div class="cart-order-detail cart-col">
                          <div class="row g-0 border-bottom pb-2">
                            <span class="col-6 col-sm-6 cart-subtotal-title">
                              <strong>Subtotal</strong>
                            </span>
                            <span class="col-6 col-sm-6 cart-subtotal-title cart-subtotal text-end">
                              <span class="money">
                                ₹{calculateTotal()}
                                { }
                              </span>
                            </span>
                          </div>

                          <div class="row g-0 pt-2">
                            <span class="col-6 col-sm-6 cart-subtotal-title fs-6">
                              <strong>Total</strong>
                            </span>
                            <span class="col-6 col-sm-6 cart-subtotal-title fs-5 cart-subtotal text-end text-primary">
                              <b class="money">₹{calculateTotal()}</b>
                            </span>
                          </div>

                          <button
                            id="cartCheckout"
                            class={`btn btn-lg my-4 checkout w-100 ${loading ? 'loading' : ''
                              }`}
                            onClick={() => handleCheckout()}
                            disabled={loading === true ? true : false}
                          >
                            {loading ? 'Processing...' : 'Place Order'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
            <br />
            <Footer />
          </div>
        </div>
      </body>
    </>
  );
};

export default CartList;

import React, { useEffect } from 'react';

import { useDispatch ,useSelector} from 'react-redux';
import { bannerSession } from '../../actions/HomePageActions'
import img from './demo4-banner1.jpg';

const Banner = () => {
  const dispatch = useDispatch();
  const bannerSession_ = useSelector((state) => state.homePageBanner);
  const { loading, error, banner } = bannerSession_;
  useEffect(() => {
    dispatch(bannerSession());
  }, []);

  return (
    <section className=" ">
      <div className=" ">
        <div className="slide">
          <div className="slideshow-wrap">
            <img
              className="blur-up lazyload"
              src="/image/web.jpg"
              alt="slideshow"
              title=""
              width="1920"
              height="990"
            />
          </div>
        </div>
      </div>
    </section>
  );
};



export default Banner;

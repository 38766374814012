import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { brandSession } from '../../actions/HomePageActions'

 


const Brand = () => {
  const dispatch = useDispatch();
  const brandSession_ = useSelector((state) => state.homePageBrand);
  const { loading, error, brand } = brandSession_;
  useEffect(() => {
    dispatch(brandSession());
  }, []);

  return (
    <section class="section logo-section pb-0">
      {/* <div class="container">
        <div class="section-header d-none">
          <h2>Popular Brands</h2>
          <p>Brand the way to a brighter day</p>
        </div>

        <div class="brands-list">
          <div class="brands-row logo-bar logo-slider-6items gp10 arwOut5 hov-arrow circle-arrow row">
            {brand?.brands?.map((brand,index) => (
              <div class="brands-logo col-2">
                <a
                  href="shop-right-sidebar.html"
                  class="zoom-scal zoom-scal-nopb"
                >
                  <img
                          class=" blur-up lazyload"
                          
                          src={
                            brand?.photo != ''
                              ? brand?.photo
                              : 'assets/images/brand/625x625.jpg'
                          }
                          alt="brand"
                          title="brand"
                          width="200"
                          height="100"
                        />
                   
                </a>
              </div>
            ))}
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default Brand;

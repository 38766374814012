import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';


import { getCategoryList } from '../../actions/HomePageActions';
import { getProductsList } from '../../actions/ProductPageAction';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Link } from 'react-router-dom';




const Categories = () => {
  const dispatch = useDispatch();
  const categoryList_ = useSelector((state) => state.homePageCategoryList);
  const { loading, error, categoryList } = categoryList_;
  console.log(categoryList)






  useEffect(() => {
    dispatch(getCategoryList());

  }, []);



  const [urlParams, setUrlParams] = useState('?');
  const [categoryFilter, setCategoryFilter] = useState('');


  const queryParameters = new URLSearchParams(window.location.search)


  const setToUrl = () => {
    let result = '?';

    if (categoryFilter) {
      let text = `&category=${categoryFilter}`;
      result = result.concat(text);
    }

  };



  useEffect(() => {
    setToUrl();
  }, [categoryFilter]);


  const products_ = useSelector((state) => state.productList);
  const { products } = products_;
  console.log(products, "in category page")



  return (
    <section className="section collection-slider pt-0">
      <div className="container">
        <div className="section-header style2 d-flex-center justify-content-sm-between">
          <div className="section-header-left text-start">
            <h2>Category</h2>
            <p>Shop our new arrivals from established brands</p>
          </div>
          {/* <div className="section-header-right text-start text-sm-end mt-3 mt-sm-0">
            View All Category <i className="icon anm anm-arw-right ms-2"></i>
          </div> */}
        </div>
        <div className="collection-slider-3items slick-arrow-dots gp15 arwOut5 hov-arrow circle-arrow row">
          {categoryList?.categories?.map((category, index) => (
            <div className="item col-item col-3" key={index}>
              <div className="product-box">
                <div className="product-image" style={{ padding: '5px', boxSizing: 'border-box' }}>
                  <Link to={`/products?category=${category._id}`}>

                    {/* <Link to={`/products?category=${category._id}`}> */}
                    <img
                      className="primary blur-up lazyload mt-4"
                      src={
                        category?.photo !== undefined
                          ? category?.photo
                          : 'assets/images/products/noimage.png'
                      }
                      alt=""
                      title="Product"
                      width="100"
                      height="808"
                      style={{ width: '100%' }}
                    />
                  </Link>
                </div>
                <div className="product-price" style={{ textAlign: 'center' }}>
                  {category.categoryName}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Categories;

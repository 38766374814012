import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { listOrder, deleteOrder } from '../../actions/orderActions';
import Axios from '../../axios/axios';
import { dateFormate } from './utils';
// import Print from './Print';
import html2canvas from 'html2canvas';
import { getProductsList } from '../../actions/ProductPageAction';
// import jsPDF from 'jspdf';
// import { PDFViewer } from '@react-pdf/renderer';
// import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer';
// import OrderPdf from './OrderPdf';

import PDF from './PDF';
import { PDFViewer, ReactPDF, PDFDownloadLink } from '@react-pdf/renderer';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer';
import { useNavigate } from 'react-router-dom';

export default function OrderList() {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const orderList_ = useSelector((state) => state.orderList);
  const { orders } = orderList_;

  const productList = useSelector((state) => state.productList);
  const { products } = productList;
  const [userData, setUserData] = useState('');

  // const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
  // console.log(storedUserInfo)

  console.log(userData);

  useEffect(() => {
    const UserInfo = localStorage.getItem('userInfo');
    console.log(UserInfo);
    if (UserInfo) {
      const { _id } = JSON.parse(UserInfo);
      console.log(_id); // data received(id)

      Axios.post('/api/order/list', { userId: _id })
        .then((response) => {
          setUserData(response.data);

          console.log(setUserData);
        })
        .catch((error) => {
          console.error(error);
        });

      dispatch(listOrder({ _id }));
    } else {
      navigate('/login')
    }
    dispatch(getProductsList(''));
  }, [dispatch]);

  console.log("dataaa", userData)

const isRentOutInit = userData?.data?.length > 0 ? userData.data[0].isRentOutInit : null;

console.log("isRentOutInit:", isRentOutInit);
  // const rentout = userData.map(item=>(
  // console.log(item?.isRentOutInit)
  // ))

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteOrder(id));
    }
  };

  console.log(products);

  // const editOrder = async (id) => {
  //   const data = await Axios.get(`/api/order/transaction/${id}`);

  //   if (data.data === 1) {
  //     window.location.href = `/order/edit/${id}`;
  //   } else {
  //     alert('Cant edit order!');
  //   }
  // };

  const getDelivered = (order) => {
    let result = order?.orderItems?.some((data) => data.isDelivered === false);

    return result;
  };

  const getReturn = (order) => {
    let result = order?.orderItems?.some((data) => data.isReturn === false);

    return result;
  };



  // const printDocument = () => {
  //   const input = document.getElementById('divToPrint');
  //   html2canvas(input).then((canvas) => {
  //     const imgData = canvas.toDataURL('image/png');
  //     const pdf = new jsPDF();
  //     pdf.addImage(imgData, 'JPEG', 50, 0);
  //     // pdf.output('dataurlnewwindow');
  //     pdf.save('download.pdf');
  //   });
  // };

  console.log(orders);
  return (
    <body class="cart-page cart-style1-page">
      <div class="page-wrapper">
        <Header />
        <div id="page-content">
          {' '}
          <div class="page-header text-center">
            <div class="container">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-between align-items-center">
                  <div class="page-title">
                    <h1>My Orders</h1>
                  </div>
                  <div class="breadcrumbs">
                    Home
                    <span class="main-title">
                      <i class="icon anm anm-angle-right-l"></i>My Orders
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>{' '}
          <div class="container">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 main-col">
                <div class="row">
                  <div class="col-lg-12 grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body">
                        {/* <div class="d-flex">
              <div class="p-2">
                {' '}
                <h4 class="card-title">Orders </h4>
              </div>

              <div class="ml-auto p-2">
                <input
                  type="search"
                  className="form-control"
                  placeholder="Search Number here..."
                  onKeyDown={(e) => search(e)}
                />
              </div>
            </div> */}
                        <div class="table-responsive">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Order Id</th>
                                <th>Customer Name</th>
                                <th>Delivered</th>
                                <th>Return</th>
                                <th>Amount</th>
                                <th> </th>
                              </tr>
                            </thead>
                            <tbody>
                              {userData?.data?.length > 0 ? (
                                userData?.data.map((order) => (
                                  <tr>
                                    <td>{dateFormate(order?.createdAt)}</td>
                                    <td>
                                      <Link to={`/order/${order._id}`}>
                                        ADC_{order?.orderId}
                                      </Link>
                                    </td>
                                    <td>
                                      <b>{order?.user?.name}</b> <br />{' '}
                                      <div className="mt-2">
                                        {order?.user?.phone1}
                                      </div>
                                    </td>

                                    <td>
                                      {getDelivered(order) === true ? (
                                        'No'
                                      ) : (
                                        
                                        // <i
                                        //   class="icon-check menu-icon"
                                        //   style={{ fontSize: '20px' }}
                                        // ></i>
                                        'Yes'
                                      )}
                                    </td>
                                    <td>
                                      {' '}
                                      {getReturn(order) === false ? (
                                        // <i
                                        //   class="icon-check menu-icon"
                                        //   style={{ fontSize: '20px' }}
                                        // ></i>
                                        'Yes'
                                      ) : (
                                        'No'
                                      )}
                                    </td>
                                    <td>{order?.orderAmount}</td>
                                    <td>
                                      <div class="d-flex flex-row">
                                        <div class="p-2">
                                          {' '}
                                          {/* <button
                                type="button"
                                class="btn btn-outline-warning btn-sm"
                                onClick={() => printDocument(order)}
                                data-toggle="modal"
                                data-target="#exampleModal"
                              >
                                print
                              </button> */}
                                        </div>{' '}
                                        <div class="p-2">
                                          {' '}
                                          <PDFDownloadLink
                                            document={
                                              <PDF
                                                products={products}
                                                customerName={order?.user?.name}
                                                customerNumber={
                                                  order?.user?.phone
                                                }
                                                address={order?.user?.address1}
                                                invoiceId={order?.orderId}
                                                orderDate={order.createdAt}
                                                advance={order?.totalReceive}
                                                amount={order?.orderAmount}
                                                items={order?.orderItems} //error
                                                discount={order?.discount}
                                              />
                                            }
                                            fileName="order.pdf"
                                          >
                                            {({ blob, url, loading, error }) =>
                                              loading ? (
                                                'Loading document...'
                                              ) : (
                                                <button
                                                  type="button"
                                                  class="btn btn-outline-primary btn-sm"
                                                >
                                                  print
                                                </button>
                                              )
                                            }
                                          </PDFDownloadLink>
                                          {/* <PDFDownloadLink
                                document={
                                  <OrderPdf
                                    products={products}
                                    customerName={order?.user?.name}
                                    customerNumber={order?.user?.phone}
                                    address={order?.user?.address1}
                                    invoiceId={order?.orderId}
                                    orderDate={order.createdAt}
                                    advance={order?.advance}
                                    amount={order?.orderAmount}
                                    items={order?.orderItems}
                                    discount={order?.discount}
                                  />
                                }
                                fileName="order.pdf"
                              >
                                {({ blob, url, loading, error }) => (
                                  <button
                                    type="button"
                                    class="btn btn-outline-primary btn-sm"
                                  >
                                    print
                                  </button>
                                )}
                              </PDFDownloadLink> */}
                                        </div>
                                        {/* <div class="p-2">
                              {' '}
                              <button
                                type="button"
                                class="btn btn-outline-dark btn-sm"
                                onClick={(e) => editOrder(order?._id)}
                              >
                                Edit
                              </button>
                              {/* </Link> */}
                                        {/* </div> */}

                                        {/* delete ///////////////////////////////// */}
                                        {! isRentOutInit &&
                                          <div class="p-2">
                                            {' '}
                                            <button
                                              type="button"
                                              class="btn btn-outline-danger btn-sm"
                                              onClick={() =>
                                                deleteHandler(order?._id)
                                              }
                                            >
                                              Delete
                                            </button>
                                          </div>  
                                      }

                                      </div>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={6}>
                                    No Order found on this number
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="modal fade"
                    id="exampleModalPrint"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                    backdrop={true}
                    data-backdrop="static"
                  >
                    {' '}
                    <form class="forms-sample">
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                              {/* {orderResponse ? orderResponse : 'Summary'} */}
                            </h5>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>

                          <div class="modal-body" id="divToPrint">
                            {/* <Print
                  customerName={searchCustomerRes}
                  customerNumber={searchCustomer}
                  address={customerDetails?.user?.address1}
                  invoiceId={orderDetails.orderId}
                  orderDate={orderDetails.createdAt}
                  advance={advance}
                  amount={totalAmount}
                  items={selectedItems}
                  discount={discount}
                /> */}
                          </div>

                          <div class="modal-footer">
                            <a href="/orders">
                              <button type="button" class="btn btn-secondary">
                                Close
                              </button>
                            </a>

                            {/* <button
                  type="button"
                  class="btn btn-primary"
                  onClick={printDocument}
                >
                  download pdf
                </button> */}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </body>
  );
}

import React from 'react';
import Footer from '../../components/Layout/Footer';
import Header from '../../components/Layout/Header/Header';
import Banner from './Banner';
import Brand from './Brand';
import Category from './Category';
import Slider from './Slider';

import Instagram from './Instagram';
import NewArrivals from './NewArrivals';
import News from './News';
import ServiceSection from './ServiceSection';
// import CartList from '../Cart/CartList';

export default function Home() {
  return (
    <body className="template-index index-demo4">
      <div className="page-wrapper">
        <div className="classic-topHeader">
          <Header />
          <div id="page-content" className="mb-0">
            <Banner />
            <br/>
            <Category />
            <NewArrivals />
            {/* <Instagram /> */}
            {/* <CartList/> */}

            <Brand />

            {/* <News /> */}
            <ServiceSection/>
            
          </div>
          <Footer />
        </div>
      </div>
    </body>
  );
}

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getNewArrivals } from '../../actions/HomePageActions';

export default function NewArrivals() {
  const dispatch = useDispatch();

  const NewArrivals = useSelector((state) => state.homePageNewArrivals);
  const { loading, error, newArrivals } = NewArrivals;

  useEffect(() => {
    dispatch(getNewArrivals());
  }, []);


  return (
    <section class="section product-banner-slider">
      <div class="container">
        <div class="section-header style2 d-flex-center justify-content-sm-between">
          <div class="section-header-left text-start">
            <h2>New Arrivals</h2>
            <p>Shop our new arrivals from established brands</p>
          </div>

          <div class="section-header-right text-start text-sm-end mt-3 mt-sm-0">
            <Link to="/products" class="btn btn-primary">
              View More <i class="icon anm anm-arw-right ms-2"></i>
            </Link>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-3 mb-4 mb-lg-0">
            <div class="ctg-bnr-wrap one position-relative h-100">
              <div class="ctg-image ratio ratio-1x1 h-100">
                <img
                  class="blur-up lazyload"
                  data-src="assets/images/collection/producs_home_page.jpg "
                  src="assets/images/collection/products_home_page.jpg"
                  alt="collection"
                  width="390"
                  height="483"
                />
              </div>
              <div class="ctg-content text-white d-flex-justify-center flex-nowrap flex-column h-100">
                <h2 class="ctg-title text-white m-0">
                  {/* <span class="d-block">Lifestyle</span> Collections */}
                </h2>
                <p class="ctg-des mt-3 mb-4">
                  Shop our products check what's new in our collections.
                </p>
                Explore Now <i class="icon anm anm-arw-right ms-2"></i>
              </div>
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-12 col-lg-9">
            <div class="grid-products product-slider-3items gp15 arwOut5 hov-arrow circle-arrow arrowlr-0 row">
              {newArrivals?.products?.slice(0, 3).map((product, index) => (
                <div class="item col-item col-md-4">
                  <div class="product-box">
                    <div class="product-image">
                      <Link to={`/product/${product._id}`}>
                        <img
                          class="primary   lazyload"
                          src={
                            product?.image1 != ''
                              ? product?.image1
                              : 'assets/images/products/noimage.png'
                          }
                          alt=""
                          title="Product"
                          width="125"
                          height="125"
                        />
                      </Link>
                    </div>

                    <div class="product-details text-left">
                      <div class="product-vendor">
                        {product?.category?.categoryName}
                      </div>

                      <div class="product-name">{product?.name}</div>

                      <div class="product-price">
                        <span class="price">₹{product?.offerPrice}</span>
                      </div>

                      <div class="button-action">
                        <div class="addtocart-btn">
                          <form class="addtocart" action="#" method="post">
                            <Link
                              to={`/product/${product._id}`}

                              // class="btn btn-secondary quick-shop quick-shop-modal"
                              // data-bs-toggle="modal"
                              // data-bs-target="#quickshop_modal"
                            >
                              <i class="icon anm anm-basket-l me-2"></i>
                              <span class="text">Quick Shop</span>
                            </Link>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <div class="item col-item col-md-4">
                <div class="product-box"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* data mapping */}
    </section>
  );
}














// const counter = useSelector(state => state.counter);

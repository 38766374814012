

export const PRODUCT_LIST_REQUEST =
  'PRODUCT_LIST_REQUEST';
export const PRODUCT_LIST_SUCCESS =
  'PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAIL =
  'PRODUCT_LIST_FAIL';




export const SINGLE_PRODUCT_LIST_REQUEST =
  'SINGLE_PRODUCT_LIST_REQUEST';
export const SINGLE_PRODUCT_LIST_SUCCESS =
  'SINGLE_PRODUCT_LIST_SUCCESS';
export const SINGLE_PRODUCT_LIST_FAIL =
  'SINGLE_PRODUCT_LIST_FAIL';


import React, { useState, useEffect } from 'react';
import Axios from '../../axios/axios';
import Header from '../../components/Layout/Header/Header';
import { useNavigate } from 'react-router-dom';
import imageCompression from 'browser-image-compression';

export default function Profile() {
  const [isloading, setIsloading] = useState(false);
  const [userDetails, setUserDetails] = useState({});


  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [address3, setAddress3] = useState('');
  const [address4, setAddress4] = useState('');
  const [phone, setPhone] = useState('');
  const [phone1, setPhone1] = useState('');
  const [id1, setId1] = useState('');
  const [id1Sec, setId1Sec] = useState('');
  const [id1Name, setId1Name] = useState('');
  const [id2, setId2] = useState('');
  const [id2Sec, setId2Sec] = useState('');
  const [id2Name, setId2name] = useState('');
  const [photo, setPhoto] = useState('');
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      let user = JSON.parse(localStorage.getItem('userInfo'));
      const { data } = await Axios.get(`/api/user/${user._id}`);
      setUserDetails(data);
      console.log("userdata",data)

      setName(data?.name);
      setEmail(data?.email);
      // setPassword(data?.password);
      setAddress1(data?.address1);
      setAddress2(data?.address2);
      setAddress3(data?.address3);
      setAddress4(data?.address4);
      setPhone(data?.phone);
      setPhone1(data?.phone1);
      setId1Name(data?.id1Name);
      setId2name(data?.id2name);
      setPhoto(data?.photo);
      setId1(data?.id1);
      setId2(data?.id2);
      setId1Sec(data?.id1Sec);
      setId2Sec(data?.id2Sec);
    } catch (error) {
      console.error(error);
    }
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const uploadFileHandler = async (e, val) => {
    const file = e.target.files[0];
    let compressedFile = file;
    if(file && file.size > 1024 * 1024){
      try {
        const options={
          maxSizeMB : 1,
          maxWidthOrHeoght : 1920,
          useWebWorker: true,
        }
         compressedFile = await imageCompression(file,options)
        setSelectedFile(compressedFile)
        
      } catch (error) {
        console.error('Error compressing the image:', error);
      }
    }
    
    const formData = new FormData();
    formData.append('image', compressedFile);
    setUploading(true);

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const { data } = await Axios.post('/api/upload', formData, config);

      switch (val) {
        case 'id1':
          setId1(data);
          break;
        case 'id1Sec':
          setId1Sec(data);
          break;
        case 'id2':
          setId2(data);
          break;
        case 'id2Sec':
          setId2Sec(data);
          break;
        case 'photo':
          setPhoto(data);
          break;
        default:
          break;
      }

      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    let obj = {
      id: userDetails._id,
      name,
      email,
      address1,
      address2,
      address3,
      address4,
      phone,
      phone1,
      // password,
      id1,
      id1Sec,
      id1Name,
      id2,
      id2Sec,
      id2name:id2Name,
      photo,
      
    };

        console.log(obj)

    //     // window.location.href = '/users';
    //   };

    try {
      const { data } = await Axios.put(`/api/user/${obj.id}`, obj);

      console.log(data);
      navigate('/profileview')
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="cart-page cart-style1-page">
      <div className="page-wrapper">
        <Header />

        <div id="page-content">
          <div className="page-header text-center">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-between align-items-center">
                  <div className="page-title">
                    <h1>My Account</h1>
                  </div>
                  <div className="breadcrumbs">
                    <a href="index.html" title="Back to the home page">
                      Home
                    </a>
                    <span className="main-title fw-bold">
                      <i className="icon anm anm-angle-right-l"></i>My Account
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <form className="forms-sample" onSubmit={submitHandler}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-3 mb-4 mb-lg-0">
                  <div className="dashboard-sidebar bg-block">
                    <div className="profile-top text-center mb-4 px-3">
                      <div className="profile-image mb-3">
                        <img
                          className="rounded-circle blur-up lazyload"
                          src={
                            userDetails.photo
                              ? userDetails.photo
                              : '/assets/images/users/user.png'
                          }
                          alt="user"
                          width="130"
                        />
                      </div>
                      <div className="profile-detail">
                        <h3 className="mb-1">{userDetails.name}</h3>
                        <p className="text-muted">{userDetails.email}</p>
                      </div>
                      <div className="profile-detail">
                        <h3 className="mb-1">Balance :₹ {userDetails.balanceAmount}</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-9">
                  <div
                    className="dashboard-content tab-content h-100"
                    id="top-tabContent"
                  >
                    <div className="tab-pane fade h-100 show active" id="info">
                      <div className="account-info h-100">
                        <div className="account-box">
                          <h3 className="mb-3">User Information</h3>
                          <div className="row row-cols-lg-2 row-cols-md-2 row-cols-sm-1 row-cols-1">
                            <div className="box-info mb-4">
                              <div className="box-title d-flex-center">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Your Name"
                                  onChange={(e) => setName(e.target.value)}
                                  value={name}
                                />
                              </div>
                              <div className="box-title d-flex-center">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Email"
                                  onChange={(e) => setEmail(e.target.value)}
                                  value={email}
                                />
                              </div>
                              <div className="box-title d-flex-center">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Place"
                                  onChange={(e) => setAddress1(e.target.value)}
                                  value={address1}
                                />
                              </div>
                              <div className="box-title d-flex-center">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Area"
                                  onChange={(e) => setAddress4(e.target.value)}
                                  value={address4}
                                />
                              </div>
                            </div>

                            <div className="box-info mb-4">
                              <div className="box-title d-flex-center">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Post"
                                  onChange={(e) => setAddress2(e.target.value)}
                                  value={address2}
                                />
                              </div>
                              <div className="box-title d-flex-center">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Pin"
                                  onChange={(e) => setAddress3(e.target.value)}
                                  value={address3}
                                />
                              </div>
                              <div className="box-title d-flex-center">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Phone"
                                  onChange={(e) => setPhone(e.target.value)}
                                  value={phone}
                                />
                              </div>
                              <div className="box-title d-flex-center">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Alternative Phone"
                                  onChange={(e) => setPhone1(e.target.value)}
                                  value={phone1}
                                />
                              </div>
                            </div>
                            <div className="box-info mb-4">
                              Your Photo
                              <div className="box-title d-flex-center">
                                <input
                                  type="file"
                                  className="form-control"
                                  placeholder="Address"
                                  onChange={(e) => uploadFileHandler(e, 'photo')}
                                />
                                <img
                                  className="rounded-circle blur-up lazyload"
                                  src={
                                    photo
                                      ? photo
                                      : '/assets/images/users/user.png'
                                  }
                                  alt="user"
                                  width="50"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row row-cols-lg-2 row-cols-md-2 row-cols-sm-1 row-cols-1">
                            <div className="box-info mb-4">
                              Id Proof 1
                              <div className="box-title d-flex-center">
                                <select
                                  className="form-control"
                                  onChange={(e) => setId1Name(e.target.value)}
                                  value={id1Name}
                                >
                                  <option value="" disabled>Select Aadhar/License</option>
                                  <option value="Aadhar">Aadhar</option>
                                  <option value="License">License</option>
                                  <option value="Passport">Passport</option>
                                  <option value="Idproof">ID Proof</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="row row-cols-lg-2 row-cols-md-2 row-cols-sm-1 row-cols-1">
                            <div className="box-info mb-4">
                              Frontside Photo
                              <div className="box-title d-flex-center">
                                <input
                                  type="file"
                                  className="form-control"
                                  onChange={(e) => uploadFileHandler(e, 'id1')}
                                />
                                {uploading && <div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div>}
                                <img
                                  src={
                                    id1
                                      ? id1
                                      : '/assets/images/select_image.png'
                                  }
                                  width="130"
                                  alt="frontside"
                                />
                              </div>
                            </div>
                            <div className="box-info mb-4">
                              Backside Photo
                              <div className="box-title d-flex-center">
                                <input
                                  type="file"
                                  className="form-control"
                                  onChange={(e) => uploadFileHandler(e, 'id1Sec')}
                                />
                                {uploading && <div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div>}
                                <img
                                  src={
                                    id1Sec
                                      ? id1Sec
                                      : '/assets/images/select_image.png'
                                  }
                                  width="130"
                                  alt="backside"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row row-cols-lg-2 row-cols-md-2 row-cols-sm-1 row-cols-1">
                            <div className="box-info mb-4">
                              Id Proof 2
                              <div className="box-title d-flex-center">
                                <select
                                  className="form-control"
                                  onChange={(e) => setId2name(e.target.value)}
                                  value={id2Name}
                                >
                                  <option value="" disabled>Select Aadhar/License</option>
                                  <option value="Aadhar">Aadhar</option>
                                  <option value="License">License</option>
                                  <option value="Passport">Passport</option>
                                  <option value="Idproof">ID Proof</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="row row-cols-lg-2 row-cols-md-2 row-cols-sm-1 row-cols-1">
                            <div className="box-info mb-4">
                              Frontside Photo
                              <div className="box-title d-flex-center">
                                <input
                                  type="file"
                                  className="form-control"
                                  onChange={(e) => uploadFileHandler(e, 'id2')}
                                />
                                {uploading && <div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div>}
                                <img
                                  src={
                                    id2
                                      ? id2
                                      : '/assets/images/select_image.png'
                                  }
                                  width="130"
                                  alt="frontside"
                                />
                              </div>
                            </div>
                            <div className="box-info mb-4">
                              Backside Photo
                              <div className="box-title d-flex-center">
                                <input
                                  type="file"
                                  className="form-control"
                                  onChange={(e) => uploadFileHandler(e, 'id2Sec')}
                                />
                                {uploading && <div className="spinner-border" role="status"><span className="sr-only">Loading...</span></div>}
                                <img
                                  src={
                                    id2Sec
                                      ? id2Sec
                                      : '/assets/images/select_image.png'
                                  }
                                  width="130"
                                  alt="backside"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="box-info mb-4">
                            <div className="box-title d-flex-center">
                              <div className="product-form-submit addcart fl-1 ms-3">
                                <button
                                  type="submit"
                                  className={`btn btn-lg my-4 checkout w-100 ${isloading && 'disabled'}`}
                                  disabled={isloading}
                                >
                                  {isloading ? 'Saving...' : 'Save'}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

